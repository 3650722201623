<template>
  <div class="manage-agency-users-edit animatedBox">
    <div class="container fluid">
      <fd-form @submit.prevent="submitForm">
        <div class="card p-3">
          <h3 class="page-title mb-4">
            Edit Agency User
            <chip class="ml-2"> ID: {{ $route.params.id }}</chip>
          </h3>
          <!-- Agency Details -->
          <fd-form-section title="Agency Details">
            <fd-select
              v-model="agencyUser.agencyId"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Select Agency"
              :options="agencyOptions"
              :validators="[validator.required]"
              @input="agencyChanged"
            >
            </fd-select>
            <fd-select
              v-model="agencyUser.branchId"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Select Branch"
              :options="branchOptions"
              :selectText="
                $isStringEmpty(agencyUser.agencyId)
                  ? 'Select an agency first'
                  : 'Select a branch'
              "
              :disabled="$isStringEmpty(agencyUser.agencyId)"
              :validators="[validator.required]"
            >
            </fd-select>
            <fd-select
              v-model="agencyUser.roleId"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Select Role"
              :options="roleOptions"
              :selectText="
                $isStringEmpty(agencyUser.agencyId)
                  ? 'Select an agency first'
                  : 'Select a role'
              "
              :disabled="$isStringEmpty(agencyUser.agencyId)"
              :validators="[validator.required]"
            >
            </fd-select>
            <fd-input
              v-model="agencyUser.renLicense"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="REN/E/PEA License"
              name="renLicense"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
          </fd-form-section>
          <fd-form-section title="Account Credential">
            <fd-input
              v-model="agencyUser.email"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Email"
              name="email"
              type="email"
              :validators="[validator.required, validator.email]"
            >
            </fd-input>
            <div class="col-12 px-1">
              <button
                type="button"
                class="btn main bordered mb-3"
                @click="changePasswordModal.isShown = true"
              >
                Change Password
              </button>
            </div>
          </fd-form-section>
          <!-- Personal Information -->
          <fd-form-section title="Personal Information">
            <div class="col-12 px-1 mb-2">
              <label class="label">Avatar</label>
              <image-uploader
                v-model="agencyUser.avatar"
                :multiple="false"
                @error="
                  (error, imageName) => {
                    $reportError(error, 'Upload avatar (Edit A.User)');
                  }
                "
              >
              </image-uploader>
            </div>
            <fd-input
              v-model="agencyUser.name"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Name"
              name="name"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <fd-input
              v-model="agencyUser.actualName"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Actual Name"
              name="actualName"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>

            <fd-select
              v-model="agencyUser.gender"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Select a gender"
              :options="genderOptions"
              :validators="[validator.required]"
            >
            </fd-select>
            <fd-date-picker
              v-model="agencyUser.birthdate"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Birth Date"
              name="birthdate"
            >
            </fd-date-picker>
            <tel-input
              ref="tel"
              v-model="phoneInput"
              :tel.sync="agencyUser.phoneNo"
              class="col-12 px-1 mb-2 d-block"
              label="Contact No."
              required
            >
            </tel-input>
            <fd-textarea
              v-model="agencyUser.introduction"
              class="col-12 px-1 mb-2"
              label="Introduction"
              name="introduction"
            >
            </fd-textarea>
            <fd-textarea
              v-model="agencyUser.experience"
              class="col-12 px-1 mb-2"
              label="Experience"
              name="experience"
            >
            </fd-textarea>
          </fd-form-section>
          <!-- Bank Details -->
          <fd-form-section title="Bank Details">
            <fd-input
              v-model="agencyUser.bank"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Bank Name"
              name="bank"
              type="text"
            >
            </fd-input>
            <fd-input
              v-model="agencyUser.bankAccountNumber"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Bank Account No."
              name="bankAccountNumber"
              type="text"
            >
            </fd-input>
            <fd-input
              v-model="agencyUser.bankAccountFullName"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Account Full Name"
              name="bankAccountFullName"
              type="text"
              :validators="[(val) => validator.min(val, 3)]"
            >
            </fd-input>
          </fd-form-section>
          <!-- Address Details -->
          <fd-form-section title="Address Details">
            <fd-input
              v-model="agencyUser.address"
              class="col-12 sm-col-6 px-1 mb-2"
              label="Address"
              name="address"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <addresses
              v-if="agencyUser.country"
              isEdit
              :country.sync="agencyUser.country"
              :state.sync="agencyUser.state"
              :city.sync="agencyUser.city"
              :area.sync="agencyUser.area"
              class="row col-12"
              @change="
                (data) => {
                  agencyUser.countryId = data.country.id;
                  agencyUser.stateId = data.state.id;
                  agencyUser.cityId = data.city.id;
                  agencyUser.areaId = data.area.id;
                  agencyUser.buildingId = data.building.id;
                }
              "
            ></addresses>
          </fd-form-section>
          <div class="text-right p-2">
            <button class="btn main">Update</button>
          </div>
        </div>
      </fd-form>
    </div>
    <!-- Modal: Change Password -->
    <modal v-model="changePasswordModal.isShown">
      <fd-form class="change-password card p-3" @submit="changePassword">
        <h3 class="mb-3">Change Password</h3>

        <fd-input
          v-model="changePasswordModal.form.password"
          class="col-12 mb-2"
          label="New Password"
          name="newPassword"
          :type="showPassword ? 'text' : 'password'"
          :validators="[validator.required, validator.password]"
        >
          <template #append-icon>
            <span class="cursor-pointer" @click="showPassword = !showPassword">
              <i
                class="fa"
                :class="{
                  'fa-eye': !showPassword,
                  'fa-eye-slash': showPassword
                }"
              ></i>
            </span>
          </template>
        </fd-input>

        <div class="text-right pt-3">
          <fd-button class="mr-1" @click="changePasswordModal.isShown = false">
            Cancel
          </fd-button>
          <fd-button type="submit" class="main">Update Password</fd-button>
        </div>
      </fd-form>
    </modal>
  </div>
</template>

<script>
import {
  required,
  email,
  phone,
  password,
  minLength
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import genderEnum from "@/enums/gender";
import Addresses from "@/components/GlobalComponents/Address";
import { agencyUser as AgencyUserAPI } from "@/api";
import { AgencyUserModel } from "@/models";
import TelInput from "@/modules/User/components/TelInput";

export default {
  components: {
    Addresses,
    TelInput,
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader"),
    Chip: () => import("@/components/GlobalComponents/Chip")
  },
  props: {},
  data: function () {
    return {
      agencyOptions: [],
      branchOptions: [],
      roleOptions: [],

      genderOptions: this.$mapJsonToArray(genderEnum, (e) => {
        return {
          id: e,
          name: e
        };
      }),

      phoneInput: "",

      agencyUser: {
        agencyId: "",
        branchId: "",
        roleId: "",
        renLicense: "",

        address: "",
        countryId: "",
        stateId: "",
        cityId: "",
        areaId: "",

        name: "",
        email: "",
        password: "",
        avatar: [],
        phoneNo: {},
        actualName: "",
        birthdate: "",
        introduction: "",
        experience: "",
        gender: "",

        bankAccountNumber: "",
        bankAccountFullName: "",
        bank: ""
      },

      showPassword: false,
      changePasswordModal: {
        isShown: false,
        form: {
          passsword: ""
        }
      },

      validator: {
        required: required,
        email: email,
        phone: phone,
        password: password,
        min: minLength
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initData();
  },
  methods: {
    async initData() {
      await Promise.all([this.getAgencyUser(), this.getAgencies()]);
      await Promise.all([this.getBranches(), this.getRoles()]);
    },
    async agencyChanged() {
      this.$store.commit("setIsLoading", true);
      // Clear branch and role selection
      this.agencyUser.branchId = "";
      this.agencyUser.roleId = "";
      // Get branch & role options
      await Promise.all([this.getBranches(), this.getRoles()]);
      this.$store.commit("setIsLoading", false);
    },
    validateFail() {
      this.$notify({
        group: "alert",
        type: "error",
        title: "Invalid Input",
        text: "Please check if the inputs are valid."
      });
    },
    // ============================== API Related ==============================
    async getAgencyUser() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await AgencyUserAPI.getAgencyUser(this.$route.params.id);

        this.agencyUser = AgencyUserModel.getAgencyUserForEditResponse(
          this._.cloneDeep(data)
        );

        this.$refs.tel.setTel(this.agencyUser);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch agency user data. Please try again later."
        });
      }
    },
    async getAgencies() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await this.$store.dispatch("manageAgency/getAllAgencies", {
          limit: 200
        });

        this.agencyOptions = this._.cloneDeep(data.data);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch agency data."
        });
      }
    },
    async getBranches() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await this.$store.dispatch("manageBranch/getAllBranches", {
          "agency:id": this.agencyUser.agencyId,
          limit: 200
        });

        this.branchOptions = this._.cloneDeep(data.data);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch branch data."
        });
      }
    },
    async getRoles() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await this.$store.dispatch(
          "manageAgency/getAgencyRoles",
          this.agencyUser.agencyId
        );

        this.roleOptions = this._.cloneDeep(data.data);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch agent's role."
        });
      }
    },
    async submitForm() {
      const id = this.$route.params.id;
      this.$store.commit("setIsLoading", true);
      try {
        await AgencyUserAPI.updateAgencyUser({
          id: id,
          payload: AgencyUserModel.postAgencyUserPayload(this.agencyUser)
        });
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Agency user edited successfully."
        });
        this.$router.push({ name: "ManageAgencyUsers" });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: " An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    },
    async changePassword() {
      try {
        this.$store.commit("setIsLoading", true);
        await AgencyUserAPI.updatePassword(
          this.$route.params.id,
          this.changePasswordModal.form
        );
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Agency user's password has been updated successfully."
        });

        this.changePasswordModal.isShown = false;
        this.changePasswordModal.form.password = "";

        await this.getAgencyUser();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update the password. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.manage-agency-users-add {
  form {
    @extend %formDesign;
  }
  .page-title {
    font-size: 24px;
  }
}
</style>
